/* LoadingScreen.tsx */
#loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: rgba(253, 179, 94);
  border-top-color: rgba(253, 179, 94);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

/* Recording.tsx */
canvas {
  box-shadow: none;
}

.canvas-success {
  animation: canvas-success-animation 0.75s 1;
}

@keyframes canvas-success-animation {
  0% {
    box-shadow: 0 0 0 0 #2cf34a;
  }
  50% {
    box-shadow: 0 0 45px 0px #2cf34a;
  }
  100% {
    box-shadow: 0 0 0 0 #2cf34a;
  }
}

.canvas-failure {
  animation: canvas-failure-animation 0.75s 1;
}

@keyframes canvas-failure-animation {
  0% {
    box-shadow: 0 0 0 0 #f35a2c;
  }
  50% {
    box-shadow: 0 0 45px 0px #f35a2c;
  }
  100% {
    box-shadow: 0 0 0 0 #f35a2c;
  }
}

.overlay {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 1rem;
  backdrop-filter: blur(30px);
  gap: 20px;
  padding: 30px;
  animation: overlay-show-hide 2s forwards;
}

@keyframes overlay-show-hide {
  0% {
    visibility: visible;
    opacity: 0;
  }
  25% {
    visibility: visible;
    opacity: 1;
  }
  50% {
    visibility: visible;
    opacity: 1;
  }
  75% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: visible;
    opacity: 0;
  }
}
